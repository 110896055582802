var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.firstSupport)?_c('div',{staticClass:"product-supports",class:{ inline: _vm.inline, }},[_c('div',{staticClass:"compatibilite flex-vcenter"},[_c('strong',{staticClass:"text-regular"},[_vm._v(_vm._s(_vm.$t('produit.compatible')))]),_c('ul',[(_vm.hasSupport('Windows'))?_c('li',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({ content: 'Windows', }),expression:"{ content: 'Windows', }"}]},[_c('UilWindows',{attrs:{"size":"16"}})],1):_vm._e(),(_vm.hasSupport('MacOS'))?_c('li',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({ content: 'MacOS', }),expression:"{ content: 'MacOS', }"}]},[_c('UilApple',{attrs:{"size":"16"}})],1):_vm._e(),(_vm.hasSupport('Linux'))?_c('li',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({ content: 'Linux', }),expression:"{ content: 'Linux', }"}]},[_c('UilLinux',{attrs:{"size":"16"}})],1):_vm._e(),(_vm.hasSupport('Android tablette'))?_c('li',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({ content: 'Android', }),expression:"{ content: 'Android', }"}]},[_c('UilAndroid',{attrs:{"size":"16"}})],1):_vm._e(),(_vm.hasSupport('iOS tablette'))?_c('li',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({ content: 'iOS', }),expression:"{ content: 'iOS', }"}]},[_c('IconIos',{attrs:{"width":"16"}})],1):_vm._e()])]),_c('div',{staticClass:"access"},[_c('div',{staticClass:"flex-vcenter"},[_c('span',{staticClass:"text-regular"},[_vm._v(_vm._s(_vm.$t('produit.acces-en-ligne')))]),_c('ul',[_c('li',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: _vm.$t('produit.acces-ent'),
            delay: { show: 800, },
          }),expression:"{\n            content: $t('produit.acces-ent'),\n            delay: { show: 800, },\n          }"}],class:_vm.firstSupport.disponible_via_ent ? 'green' : 'red'},[_c('UilServerNetwork',{attrs:{"size":"16"}})],1),_c('li',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: _vm.$t('produit.acces-sans-ent'),
            delay: { show: 800, },
          }),expression:"{\n            content: $t('produit.acces-sans-ent'),\n            delay: { show: 800, },\n          }"}],class:_vm.firstSupport.disponible_hors_ent ? 'green' : 'red'},[_c('UilWindow',{attrs:{"size":"16"}})],1),_c('li',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: _vm.$t('produit.acces-gar'),
            delay: { show: 800, },
          }),expression:"{\n            content: $t('produit.acces-gar'),\n            delay: { show: 800, },\n          }"}],class:_vm.product.disponible_via_gar ? 'green' : 'red'},[_c('IconGar',{attrs:{"height":"16"}})],1)])]),_c('div',{staticClass:"flex-vcenter"},[_c('span',{staticClass:"text-regular"},[_vm._v(_vm._s(_vm.$t('produit.acces-hors-ligne')))]),_c('ul',[_c('li',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: _vm.$t('produit.telechargement-ent'),
            delay: { show: 800, },
          }),expression:"{\n            content: $t('produit.telechargement-ent'),\n            delay: { show: 800, },\n          }"}],class:_vm.firstSupport.disponible_via_ent && _vm.firstSupport.one_clic ? 'green' : 'red'},[_c('UilServerNetwork',{attrs:{"size":"16"}})],1),_c('li',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: _vm.$t('produit.manuel-accessible-hors-ligne'),
            delay: { show: 800, },
          }),expression:"{\n            content: $t('produit.manuel-accessible-hors-ligne'),\n            delay: { show: 800, },\n          }"}],class:_vm.firstSupport.utilisable_hors_ligne ? 'green' : 'red'},[_c('UilWindow',{attrs:{"size":"16"}})],1),_c('li',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: _vm.$t('produit.telechargement-gar'),
            delay: { show: 800, },
          }),expression:"{\n            content: $t('produit.telechargement-gar'),\n            delay: { show: 800, },\n          }"}],class:_vm.product.disponible_via_gar && _vm.firstSupport.one_clic ? 'green' : 'red'},[_c('IconGar',{attrs:{"height":"16"}})],1)])])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }