<template>
  <div
    v-if="firstSupport"
    class="product-supports"
    :class="{ inline: inline, }"
  >
    <div class="compatibilite flex-vcenter">
      <strong class="text-regular">{{ $t('produit.compatible') }}</strong>
      <ul>
        <li
          v-if="hasSupport('Windows')"
          v-tooltip="{ content: 'Windows', }"
        >
          <UilWindows size="16" />
        </li>
        <li
          v-if="hasSupport('MacOS')"
          v-tooltip="{ content: 'MacOS', }"
        >
          <UilApple size="16" />
        </li>
        <li
          v-if="hasSupport('Linux')"
          v-tooltip="{ content: 'Linux', }"
        >
          <UilLinux size="16" />
        </li>
        <li
          v-if="hasSupport('Android tablette')"
          v-tooltip="{ content: 'Android', }"
        >
          <UilAndroid size="16" />
        </li>
        <li
          v-if="hasSupport('iOS tablette')"
          v-tooltip="{ content: 'iOS', }"
        >
          <IconIos width="16" />
        </li>
      </ul>
    </div>
    <div class="access">
      <div class="flex-vcenter">
        <span class="text-regular">{{ $t('produit.acces-en-ligne') }}</span>
        <ul>
          <li
            v-tooltip="{
              content: $t('produit.acces-ent'),
              delay: { show: 800, },
            }"
            :class="firstSupport.disponible_via_ent ? 'green' : 'red'"
          >
            <UilServerNetwork size="16" />
          </li>
          <li
            v-tooltip="{
              content: $t('produit.acces-sans-ent'),
              delay: { show: 800, },
            }"
            :class="firstSupport.disponible_hors_ent ? 'green' : 'red'"
          >
            <UilWindow size="16" />
          </li>
          <li
            v-tooltip="{
              content: $t('produit.acces-gar'),
              delay: { show: 800, },
            }"
            :class="product.disponible_via_gar ? 'green' : 'red'"
          >
            <IconGar height="16" />
          </li>
        </ul>
      </div>
      <div class="flex-vcenter">
        <span class="text-regular">{{ $t('produit.acces-hors-ligne') }}</span>
        <ul>
          <li
            v-tooltip="{
              content: $t('produit.telechargement-ent'),
              delay: { show: 800, },
            }"
            :class="firstSupport.disponible_via_ent && firstSupport.one_clic ? 'green' : 'red'"
          >
            <UilServerNetwork size="16" />
          </li>
          <li
            v-tooltip="{
              content: $t('produit.manuel-accessible-hors-ligne'),
              delay: { show: 800, },
            }"
            :class="firstSupport.utilisable_hors_ligne ? 'green' : 'red'"
          >
            <UilWindow size="16" />
          </li>
          <li
            v-tooltip="{
              content: $t('produit.telechargement-gar'),
              delay: { show: 800, },
            }"
            :class="product.disponible_via_gar && firstSupport.one_clic ? 'green' : 'red'"
          >
            <IconGar height="16" />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import IconGar from "@/components/icons/IconGar.vue";
import IconIos from "@/components/icons/IconIos.vue";
import {
  UilWindows,
  UilApple,
  UilAndroid,
  UilServerNetwork,
  UilWindow,
  UilLinux,
} from "@iconscout/vue-unicons";

/**
 * Affiche les compatibilités et les supports du produit (vert pour disponible, sinon rouge). Une
 * description de chaque icône apparaît au survol.
 */
export default {
  name: "ManuelNumeriqueSupports",
  components: {
    UilWindows,
    UilApple,
    UilAndroid,
    UilServerNetwork,
    UilWindow,
    UilLinux,
    IconGar,
    IconIos,
  },
  props: {
    /**
     * Produit affiché.
     */
    product: {
      type: Object,
      required: true,
    },
    /**
     * Afiche le composant sans retour à la ligne.
     */
    inline: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    firstSupport() {
      if (this.product.technologie) {
        return this.product.technologie.supports.find((sup) => sup.support.libelle === "Windows");
      }
      return null;
    },
  },
  methods: {
    /**
     * Vérifie si le manuel est compatible avec un support donné (Windows, Android, Mac...).
     * @param {String} supportName Nom du support.
     * @returns {Object} Infos du support.
     */
    hasSupport(supportName) {
      if (this.product.technologie) {
        return this.product.technologie.supports.find((s) => s.support.libelle === supportName);
      }
      return null;
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/components/manuels_numeriques/manuel_numerique_supports.scss";
</style>
